import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import {
  banque_populaire,
  banque_tarneaud,
  caisse_epargne,
  credit_agricole,
  credit_maritime,
  credit_mutuel,
} from "../img/partners"

import {
  Banner,
  Button,
  Card,
  Head,
  Grid,
  Layout,
  Link,
  Section,
  Testimonial,
  Typography,
  Separator,
  Slider,
  Stars,
} from "../components"

export const IndexPageTemplate = ({
  title,
  subheading,
  cards,
  section_1,
  section_2,
  testimonials,
}) => (
  <>
    <Banner interior={false} title={title} subheading={subheading}>
      <Button to="/nous-contacter">Demander un prêt</Button>
    </Banner>
    <Grid container>
      {cards.map((card, index) => (
        <Grid key={`card-${index}`}>
          <Card
            title={card.title}
            variant={card.variant || "default"}
            link={card.link}
          >
            <Typography>{card.content}</Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
    <Section
      variant="image-right"
      image={
        section_1.image.childImageSharp
          ? section_1.image.childImageSharp.fluid
          : null
      }
    >
      {section_1.content.map((subSection, i) => (
        <Fragment key={`section-1-s-${i}`}>
          <Typography tag="h2" variant="large">
            {subSection.title}
          </Typography>
          {subSection.content.map((subSectionText, j) => (
            <Typography key={`section-1-s-${i}-${j}`}>
              {subSectionText}
            </Typography>
          ))}
          {subSection.link && subSection.link.to && (
            <Link to={`/${subSection.link.to}`}>{subSection.link.label}</Link>
          )}
        </Fragment>
      ))}
    </Section>
    {testimonials && (
      <Section>
        <Typography tag="h2" variant="large">
          Nos clients témoignent
        </Typography>
        <Stars />
        <Grid container>
          {testimonials.map(item => {
            const testimonial = item.node.frontmatter
            return (
              <Grid
                key={`testimonial-${testimonial.title}-${testimonial.date}`}
              >
                <Testimonial
                  title={testimonial.title}
                  content={testimonial.content}
                  author={testimonial.author}
                  date={testimonial.date}
                />
              </Grid>
            )
          })}
        </Grid>
        <Button to="/temoignages" variant="outlined">
          Voir tous les avis
        </Button>
      </Section>
    )}
    <Section
      variant="image-left"
      image={
        section_2.image.childImageSharp
          ? section_2.image.childImageSharp.fluid
          : null
      }
    >
      {section_2.content.map((subSection, i) => (
        <Fragment key={`section-2-s-${i}`}>
          <Typography tag="h2" variant="large">
            {subSection.title}
          </Typography>
          {subSection.subtitle && (
            <Typography tag="h3" variant="medium">
              {subSection.subtitle}
            </Typography>
          )}
          {subSection.content.map((subSectionText, j) => (
            <Typography key={`section-2-s-${i}-${j}`}>
              {subSectionText}
            </Typography>
          ))}
          {subSection.link && subSection.link.to && (
            <Link to={`/${subSection.link.to}`}>{subSection.link.label}</Link>
          )}
          {i < section_2.content.length - 1 && <Separator />}
        </Fragment>
      ))}
    </Section>
    <Section>
      <Typography tag="h2" variant="large">
        Quelques exemples de nos banques partenaires
      </Typography>
      <Slider
        slides={[
          { alt: "Banque Tarneaud", src: banque_tarneaud },
          { alt: "Crédit Maritime", src: credit_maritime },
          { alt: "Crédit Mutuel", src: credit_mutuel },
          { alt: "Caisse d'épargne", src: caisse_epargne },
          { alt: "Banque populaire", src: banque_populaire },
          { alt: "Crédit Agricole", src: credit_agricole },
        ]}
      />
    </Section>
  </>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  cards: PropTypes.array,
  section_1: PropTypes.object,
  section_2: PropTypes.object,
  testimonials: PropTypes.array,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.main
  const { edges: testimonials } = data.testimonials
  return (
    <Layout>
      <Head />
      <IndexPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        cards={frontmatter.cards}
        section_1={frontmatter.section_1}
        section_2={frontmatter.section_2}
        testimonials={testimonials}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    main: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subheading
        cards {
          title
          content
          link {
            label
            to
          }
        }
        section_1 {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content {
            title
            content
          }
        }
        section_2 {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content {
            title
            subtitle
            content
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "testimonial" } } }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            content
            author
            date(formatString: "DD/MM/YYYY")
          }
        }
      }
    }
  }
`
